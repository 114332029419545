import { Controller } from 'stimulus';

export default class extends Controller {

  openAllLevels(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    $('.custom-nestable-group .collapse').collapse('show');
  }

  closeAllLevels(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    $('.custom-nestable-group .collapse').collapse('hide');
  }

  moveUp(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentGroup = $("#"+event.currentTarget.closest('.custom-nestable-group').id);
    currentGroup.prev().insertAfter(currentGroup);
    this.postUpdatedOrder(currentGroup.closest('.child-nodes'));
  }

  moveDown(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentGroup = $("#"+event.currentTarget.closest('.custom-nestable-group').id);
    currentGroup.next().insertBefore(currentGroup);
    this.postUpdatedOrder(currentGroup.closest('.child-nodes'));
  }

  postUpdatedOrder(childNodesGroup) {
    let nodeIds = [];
    let items = childNodesGroup.find('> .custom-nestable-group');
    items.each((idx) => {
      nodeIds.push(items[idx].dataset.id);
    });

    let reorderUrl = childNodesGroup.data('reorder-url');

    $.post(reorderUrl, {nodeIds: nodeIds})
  }


  modelTypeInitValue(event) {
    this.modelTypeValue = event.currentTarget.value;
  }

  modelTypeChange(event) {
    event.preventDefault();

    let change = confirm(event.currentTarget.dataset.confirm);
    if (change) {
      this.modelTypeValue = event.currentTarget.value;
    } else {
      event.currentTarget.value = this.modelTypeValue;
    }
  }

}
