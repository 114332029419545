import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["item", "itemList", "addBtn"];

  connect() {
    this.maxItems = this.element.dataset.maxItems;
    this.manageAddBtnVisibility();
  }

  add(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const url = button.dataset.url;
    $.get(url).done((itemHtml) => {
      $(this.itemListTarget).append(itemHtml);
      this.manageAddBtnVisibility();
    });
  }

  remove(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const confirmMsg = button.dataset.confirmMsg || "Sei sicuro?";
    const doRemove = confirm(confirmMsg);
    if ( doRemove ) {
      button.closest('.component-collection-item').remove();
      this.manageAddBtnVisibility();
    }
  }

  moveDown(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const item = button.closest('.component-collection-item');
    const nextItem = item.nextElementSibling
    if ( nextItem ) {
      this.itemListTarget.insertBefore(item, nextItem.nextElementSibling);
    }
  }

  moveUp(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const item = button.closest('.component-collection-item');
    const prevItem = item.previousElementSibling;
    if ( prevItem ) {
      this.itemListTarget.insertBefore(item, prevItem);
    }
  }

  manageAddBtnVisibility() {
    if ( this.maxItems ) {
      if ( this.itemTargets.length < this.maxItems ) {
        this.addBtnTarget.classList.remove('hidden');
      } else {
        this.addBtnTarget.classList.add('hidden');
      }
    }
  }
}
