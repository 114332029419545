import {Controller} from 'stimulus';

export default class extends Controller {

  initialize() {
    this.previewEl = this.element.dataset.previewEl;
    this.valueEl = this.element.dataset.valueEl;
  }

  setAttachment(event) {
    event.preventDefault();

    $(this.previewEl).find('a.attachment-title').html(event.currentTarget.dataset.attachmentTitle);
    $(this.previewEl).find('a.attachment-title').attr('href', event.currentTarget.dataset.attachmentUrl);
    $(this.previewEl).find('.attachment-description').html(event.currentTarget.dataset.attachmentDescription);
    $(this.valueEl).val(event.currentTarget.dataset.attachmentId);
    $(this.previewEl).closest('.component-attachment').find('.add-link').addClass('hidden');
    $(this.previewEl).closest('.component-attachment').find('.remove-link').removeClass('hidden');

    $(this.element).closest('.modal').modal('hide');
  }

  resetAttachment(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const confirmMsg = button.dataset.confirmMsg || "Sei sicuro?";
    const doRemove = confirm(confirmMsg);
    if ( doRemove ) {
      $(this.previewEl).find('a.attachment-title').html('');
      $(this.previewEl).find('a.attachment-title').attr('href', '');
      $(this.previewEl).find('.attachment-description').html('');
      $(this.valueEl).val('');
      $(this.previewEl).closest('.component-attachment').find('.remove-link').addClass('hidden');
      $(this.previewEl).closest('.component-attachment').find('.add-link').removeClass('hidden');

      console.log($(this.element).find('.add-link'));
      console.log($(this.element).find('.remove-link'));

    }
  }

}
