import "./images";
import "../stylesheets/application.scss";
import "channels"
import "../controllers"

import './page_config_nodes'
import './web_forms'

import MyApp from "./my_app";

if ( window ) {
  window.MyApp = MyApp;
}
