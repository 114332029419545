import {Controller} from 'stimulus';
import parameterize from 'parameterize-string';

export default class extends Controller {
  static targets = ["input", "output"];
  static values = { separator: String };

  change(event) {
    let value = this.inputTarget.value;
    let separator = this.separatorValue || "-";
    this.outputTargets.forEach(outEl => {
      outEl.value = parameterize(value, {separator});
    });
  }
}
