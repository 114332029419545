import {Controller} from 'stimulus';
import MediumEditor from '../../../app/assets/javascripts/medium-editor/medium-editor'

export default class extends Controller {

  connect() {
    let allHtmlTags = this.htmlTags();
    let options = {
      paste: {
        cleanPaste: function(text){
          text = text.replace('<strong', '<b').replace('</strong>', '</b>')
          let buttons = []
          this.base.options.toolbar.buttons.forEach(el => {
            if(el == 'bold') {
              buttons.push('b')
            }else if(el == 'italic'){
              buttons.push('i')
            }else if(el == 'underline'){
              buttons.push('u')
            }else if(el == 'subscript'){
              buttons.push('sub')
            }else if(el == 'superscript'){
              buttons.push('sup')
            }else if(el == 'anchor'){
              buttons.push('a')
            }else if(el == 'orderedlist'){
              buttons.push('ol')
              buttons.push('li')
            }else if(el == 'unorderedlist'){
              buttons.push('ul')
              buttons.push('li')
            }else{
              buttons.push(el)
            }
          });
          let unwrapTags = allHtmlTags.filter((value, index, arr) => {
            return buttons.indexOf(value) < 0
          });
          this.unwrapTags = unwrapTags;
          this.pasteHTML(text)
        },
        forcePlainText: true,
        cleanPastedHTML: true,
        cleanReplacements: [],
        cleanAttrs: ['class', 'style', 'dir'],
        cleanTags: ['meta']
        // unwrapTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'figure', 'img', 'picture', 'source', 'figcaption', 'sub', 'sup']
      },
      toolbar: {
        buttons: this.mediumEditorToolbar(),
      },
      anchor: {
        targetCheckbox: true,
        targetCheckboxText: 'Open in new window'
      }
    };

    if(this.element.dataset.singleParagraph) {
      options = {...options, useBrOnReturn: true, disableReturn: true, disableDoubleReturn: true}
    }

    let editor = new MediumEditor(this.element, options);

    if ( options.useBrOnReturn ) {
      editor.subscribe("editableKeydownEnter", this.useBrOnReturnCallback.bind(editor));
    }

    if(editor.options.toolbar){
      // Inibisco le scorciatoie da tastiera per le formattazioni non presenti sulla toolbar
      let index_h1 = editor.options.toolbar.buttons.indexOf('h1');
      let index_h2 = editor.options.toolbar.buttons.indexOf('h2');
      let index_h3 = editor.options.toolbar.buttons.indexOf('h3');
      let index_h4 = editor.options.toolbar.buttons.indexOf('h4');
      let index_h5 = editor.options.toolbar.buttons.indexOf('h5');
      let index_h6 = editor.options.toolbar.buttons.indexOf('h6');
      let index_bold = editor.options.toolbar.buttons.indexOf('bold');
      let index_italic = editor.options.toolbar.buttons.indexOf('italic');
      let index_underline = editor.options.toolbar.buttons.indexOf('underline');
      editor.subscribe('editableKeydown', function (event, editable) {
        if(event.code === 'Digit1' && event.ctrlKey && event.shiftKey){
          if(index_h1 < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        } else if(event.code === 'Digit2' && event.ctrlKey && event.shiftKey){
          if(index_h2 < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        } else if(event.code === 'Digit3' && event.ctrlKey && event.shiftKey){
          if(index_h3 < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        } else if(event.code === 'Digit4' && event.ctrlKey && event.shiftKey){
          if(index_h4 < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        } else if(event.code === 'Digit5' && event.ctrlKey && event.shiftKey){
          if(index_h5 < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        } else if(event.code === 'Digit6' && event.ctrlKey && event.shiftKey){
          if(index_h6 < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        } else if(event.code === 'KeyB' && event.shiftKey){
          if(index_bold < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        } else if(event.code === 'KeyI' && event.shiftKey){
          if(index_italic < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        } else if(event.code === 'KeyU' && event.shiftKey){
          if(index_underline < 0) MediumEditor.util.execFormatBlock(editor.options.ownerDocument, 'p')
        }
      });
    }
  }

  mediumEditorToolbar() {
    let configToolbar = this.element.dataset.configToolbar.split(",");
    let editorToolbar = [];

    if(configToolbar.find(el => el === "h1")) editorToolbar.push("h1")
    if(configToolbar.find(el => el === "h2")) editorToolbar.push("h2")
    if(configToolbar.find(el => el === "h3")) editorToolbar.push("h3")
    if(configToolbar.find(el => el === "h4")) editorToolbar.push("h4")
    if(configToolbar.find(el => el === "h5")) editorToolbar.push("h5")
    if(configToolbar.find(el => el === "h6")) editorToolbar.push("h6")
    if(configToolbar.find(el => el === "bold")) editorToolbar.push("bold")
    if(configToolbar.find(el => el === "italic")) editorToolbar.push("italic")
    if(configToolbar.find(el => el === "underline")) editorToolbar.push("underline")
    if(configToolbar.find(el => el === "sub")) editorToolbar.push("subscript")
    if(configToolbar.find(el => el === "sup")) editorToolbar.push("superscript")
    if(configToolbar.find(el => el === "link")) editorToolbar.push("anchor")
    if(configToolbar.find(el => el === "ol")) editorToolbar.push("orderedlist")
    if(configToolbar.find(el => el === "ul")) editorToolbar.push("unorderedlist")

    return editorToolbar;
  }

  useBrOnReturnCallback(event) {
    event.preventDefault();
    let node = MediumEditor.selection.getSelectionStart(this.options.ownerDocument),
      html = "<br>";
    if ( MediumEditor.selection.getCaretOffsets(node).right === 0 ) {
      html = "<br><br>";
    }
    MediumEditor.util.insertHTMLCommand(this.options.ownerDocument, html);
  }

  htmlTags(){
    return [
      'a', 'abbr', 'address', 'embed', 'object', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo',
      'blockquote', 'body', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'data', 'datalist',
      'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure',
      'font', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hr', 'html', 'i', 'iframe',
      'img', 'input', 'ins', 'kbd', 'labe', 'legend', 'li', 'link', 'main', 'map', 'mark', 'meta', 'meter', 'nav',
      'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q',
      'rp', 'rt', 's', 'samp', 'script', 'section', 'select', 'small', 'source', 'span', 'strike', 'strong', 'style',
      'sub', 'summary', 'sup', 'svg', 'table', 'tbody', 'td', 'template', 'textarea', 'tfoot', 'th', 'thead', 'time',
      'title', 'tr', 'track', 'tt', 'u', 'ul', 'var', 'video', 'wbr'
    ]
  }

}
