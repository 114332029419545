import {Controller} from "stimulus";

export default class extends Controller {

  static values = {
    url: String
  }

  moveUp(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentItem = $("#"+event.currentTarget.closest('.sortable-item').id)
    currentItem.prev().insertAfter(currentItem);
    this.postUpdatedOrder(currentItem.closest('.sortable-items'));
  }

  moveDown(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentItem = $("#"+event.currentTarget.closest('.sortable-item').id)
    currentItem.next().insertBefore(currentItem);
    this.postUpdatedOrder(currentItem.closest('.sortable-items'));
  }

  postUpdatedOrder(parentNode) {
    let itemIds = [];
    let items = parentNode.find('> .sortable-item');
    items.each((idx) => {
      itemIds.push(items[idx].dataset.id);
    });

    $.post(this.urlValue, {itemIds: itemIds})
  }

}
