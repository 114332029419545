import { Controller } from 'stimulus';

export default class extends Controller {

  showChildNodes(event) {
    event.preventDefault();

    let url = event.currentTarget.closest('a').dataset.url;
    let pageNodeId = event.currentTarget.closest('a').dataset.pageNodeId;

    if ( $("#page-node-collapse-"+pageNodeId).find(".page-node-group").length === 0 ) {
      $.ajax({
        type: 'GET',
        url: url,
        success: (data) => {
          $("#page-node-collapse-" + pageNodeId).html(data.nodes);
        }
      });
    }
  }

  moveUp(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentGroup = $("#"+event.currentTarget.closest('.page-node-group').id);
    currentGroup.prev().insertAfter(currentGroup);
    this.postUpdatedOrder(currentGroup.closest('.child-nodes'));
  }

  moveDown(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentGroup = $("#"+event.currentTarget.closest('.page-node-group').id);
    currentGroup.next().insertBefore(currentGroup);
    this.postUpdatedOrder(currentGroup.closest('.child-nodes'));
  }

  postUpdatedOrder(childNodesGroup) {
    let nodeIds = [];
    let items = childNodesGroup.find('> .page-node-config-group-creatable > .page-node-group');
    items.each((idx) => {
      nodeIds.push(items[idx].dataset.id);
    });

    let reorderUrl = childNodesGroup.data('reorder-url');

    $.post(reorderUrl, {nodeIds: nodeIds})
  }

}
