import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['fileInput', 'removeInput'];

  triggerBrowse() {
    console.log("this.fileInputTarget");
    console.log(this.fileInputTarget);
    this.fileInputTarget.click();
  }

  removeAttachment() {
    this.removeInputTarget.value = "1";
    $(this.removeInputTarget).trigger('change');
  }
}
