import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["sectionsList"];
  static values = { addSectionUrl: String};

  connect() {
    $(".mt-sections-list").sortable(
      {
        handle: ".mt-section-handle",
        tolerance: 'pointer',
        forcePlaceholderSize: true,
        opacity: 0.7,
        scope: 'model-type-section'
      }
    );
  }

  addSection(event) {
    event.preventDefault();

    $.ajax({
      type: 'GET',
      url: this.addSectionUrlValue,
      dataType: 'json',
      success: (data) => {
        this.sectionsListTarget.insertAdjacentHTML('beforeend', data.section);
      }
    });
  }

  removeSection(event) {
    event.preventDefault();

    let remove = confirm("Sei sicuro di voler eliminare la sezione?");
    if (remove) {
      event.currentTarget.closest('.mt-section').remove();
    }
  }

  toggleBlock(event) {
    let target = event.currentTarget;
    if(target.checked) {
      target.closest('.mt-section-block').querySelectorAll('.mt-section-block-config input[type="checkbox"]').forEach(element => {
        element.disabled = false;
      });
    } else {
      target.closest('.mt-section-block').querySelectorAll('.mt-section-block-config input[type="checkbox"]').forEach(element => {
        element.disabled = true;
      });
    }
  }

}
