import {Controller} from 'stimulus';

export default class LocalizationsController extends Controller {

  static targets = ['master', 'fallbackWrapper', 'fallbackInput']

  toggleMaster(event) {
    const isMaster = this.masterTarget.checked
    if ( isMaster ) {
      this.fallbackWrapperTarget.classList.add('d-none')
      this.lastFallbackVal = this.fallbackInputTarget.value
      this.fallbackInputTarget.value = ''
    } else {
      this.fallbackInputTarget.value = this.lastFallbackVal || ''
      this.fallbackWrapperTarget.classList.remove('d-none')
    }
  }

}
