import { Controller } from 'stimulus';
// import JSHINT from 'jshint';
import jsonlint from '../app/jsonlint/jsonlint';
import Codemirror from 'codemirror';
import 'codemirror/addon/display/autorefresh'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/json-lint';

if ( window ) {
  // window.JSHINT = JSHINT;
  window.jsonlint = jsonlint;
}

export default class extends Controller {

  connect() {
    Codemirror.fromTextArea(this.element, JSON.parse(this.element.dataset.codemirrorOptions));
  }

}
