import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    "fileList", "browseButton", "dropElement", "container", "toolbar", "cancelUpload", "saveUpload", "resetUpload",
    "tags"
  ];

  initialize() {
    this.imgEl = this.element.dataset.imgEl;
    this.valueEl = this.element.dataset.valueEl;
    this.extensions = this.element.dataset.extensions;
    this.minWidth = this.element.dataset.minWidth;
    this.minHeight = this.element.dataset.minHeight;
    this.uploadUrl = this.element.dataset.uploadUrl;
    this.formAuthenticityToken = this.element.dataset.formAuthenticityToken;
    this.saveUploadText = $(this.saveUploadTarget).html();
    this.saveUploadDisabledText = this.saveUploadTarget.dataset.disbleWith;
    this.element.AddImages = this;
  }

  connect() {
    this.initUploader();
    this.resetUploader();
    this.initTagsInput();
  }

  disconnect() {
    this.uploader.destroy();
  }

  initUploader() {
    this.uploader = new plupload.Uploader({
      runtimes: 'html5',
      browse_button: this.browseButtonTarget,
      drop_element: this.hasDropElementTarget ? this.dropElementTarget : this.containerTarget,
      container: this.containerTarget,
      url: this.uploadUrl,
      file_data_name: 'image[image_file]',
      multi_selection: false,
      multipart_params: {
        authenticity_token: this.formAuthenticityToken
      },
      filters : {
        image_min_dimensions : { width: this.minWidth, height: this.minHeight },
        mime_types: [{title : "Image files", extensions : this.extensions}]
      }
    });

    this.uploader.bind("PostInit", this.handlePostInit.bind(this));
    this.uploader.bind("FilesAdded", this.handleFilesAdded.bind(this));
    this.uploader.bind("FilesRemoved", this.handleFilesRemoved.bind(this));
    this.uploader.bind("UploadProgress", this.handleUploadProgress.bind(this));
    this.uploader.bind("FileUploaded", this.handleFileUploaded.bind(this));
    this.uploader.bind("Error", this.handleError.bind(this));

    this.uploader.init();
  }

  resetUploader() {
    if ( this.uploader ) {
      // rimuovo tutti i file dalla coda (uno solo...)
      if (this.uploader.files.length > 0) {
        this.uploader.splice();
      }
    }
    $(this.fileListTarget).html("");
    $(this.toolbarTarget).hide();
    $(this.browseButtonTarget).show();
    $(this.cancelUploadTarget).attr('disabled', null);
    $(this.saveUploadTarget).attr('disabled', true).html(this.saveUploadText);
  }

  initTagsInput() {
    $(this.tagsTarget).tagsinput({
      tagClass: 'label label-info',
      typeahead: {
        afterSelect: function(val) { this.$element.val(""); },
        source: this.tagsTarget.dataset.source.split(","),
        autoSelect: false
      },
      trimValue: true
    });
  }

  handlePostInit() {
    $(this.fileListTarget).html('');
  }

  handleFilesAdded(up, files) {
    let i = 0;

    plupload.each(files, (file) => {
      if ( i >= 1 ) {
        setTimeout(() => { up.removeFile(file); }, 50);
      } else {
        $(this.fileListTarget).html(`<div id="${file.id}" class="image-preview"></div>`);

        let img = new mOxie.Image();

        /*img.onload = function() {
          this.embed($(`#${file.id}`).get(0));
        };
        img.load(file.getSource());*/

        var reader = new window.FileReader();
        reader.readAsDataURL(file.getNative());
        reader.onload = function () {
          var base64data = reader.result;
          base64data = base64data.substring(base64data.indexOf(",") + 1);
          var base_64_url = "data:" + file.type + ";base64," + base64data;

          console.log("base_64_url: ", base_64_url);
          console.log("$('.image-preview'): ", $('.image-preview'));

          $('.image-preview').html("<img src='"+base_64_url+"' width='400' />");
        }

        $(this.saveUploadTarget).attr('disabled', null).html(this.saveUploadText);
      }
      i++;
    });

    if ( i >= 1 ) {
      $(this.browseButtonTarget).hide();
      $(this.toolbarTarget).show();
    }
  }

  handleFilesRemoved(up, files) {
    plupload.each(files, (file) => {
      $(`#${file.id}`).remove();
    });
    if (up.files.length < 1) {
      $(this.cancelUploadTarget).attr('disabled', null);
      $(this.saveUploadTarget).attr('disabled', true).html(this.saveUploadText);
      $(this.browseButtonTarget).show();
    }
  }

  handleUploadProgress(up, file) {
    const $fileEl = $(`#${file.id}`);
    $fileEl.find('.progress-bar').css('width', `${file.percent}%`);
    $fileEl.find('span').text(`${file.percent}%`);
  }

  handleFileUploaded(up, file, http) {
    $(this.cancelUploadTarget).attr('disabled', null);
    if ( http.status === 200 ) {
      const response = $.parseJSON(http.response);
      if ( response.error ) {
        const $fileEl = $(`#${file.id}`);
        $fileEl.find('.progress').remove();
        $fileEl.append(`<div class="alert alert-danger">${response.error}</div>`);
      } else {
        // al termine dell'upload chiudo la modale
        $(this.element).closest('.modal').modal('hide');
        // assegno i valori
        $(this.imgEl).attr('src', response.url.gallery_big);
        $(this.valueEl).val(response.image_id);
        this.resetUploader();
      }
    }
  }

  handleError(up, err) {
    this.resetUploader();
    let msg = "Si è verificato un errore inatteso. Si prega di riprovare o di contattare l'amministratore del sistema.";
    if ( err.code === plupload.IMAGE_MIN_DIMENSIONS_ERROR ) {
      msg = "Il file è troppo piccolo.";
    }
    $(this.fileListTarget).html("<div class=\"alert alert-danger\">"+msg+"</div>");
  }

  startUpload(event) {
    event.preventDefault();
    event.stopPropagation();

    $(this.cancelUploadTarget).attr('disabled', true);
    $(this.saveUploadTarget).attr('disabled', true).html(this.saveUploadDisabledText);
    $(this.toolbarTarget).hide();

    $(this.fileListTarget).find('.image-preview').append(
      '<div class="progress progress-striped">' +
        '<div class="progress-bar progress-bar-warning" style="width: 0;">' +
          '<span></span>' +
        '</div>' +
      '</div>'
    );

    this.uploader.settings.url += "?tags=" + this.tagsTarget.value;

    this.uploader.start();
  }

}
