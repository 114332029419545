import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ["langWrapper"];
  static values = { lang: String };

  changeLanguage(event) {
    event.preventDefault();
    this.langValue = event.currentTarget.dataset.lang;
  }

  langValueChanged() {
    this.langWrapperTargets.forEach(element => {
      element.hidden = element.dataset.lang !== this.langValue;
    });
  }

}
