import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["version", "slugWrapper", "paperclipOptionsWrapper"];

  connect() {
    this.changeVersion();
  }

  changeVersion() {
    let version = this.versionTarget.value;

    if(version === 'V01') {
      this.slugWrapperTarget.classList.remove('hidden');
      this.slugWrapperTarget.querySelector("input").removeAttribute('disabled');
      this.paperclipOptionsWrapperTarget.classList.add('hidden');
      this.paperclipOptionsWrapperTarget.querySelector("textarea").setAttribute('disabled', 'disabled');
    } else if(version === 'V02') {
      this.slugWrapperTarget.classList.add('hidden');
      this.slugWrapperTarget.querySelector("input").setAttribute('disabled', 'disabled');
      this.paperclipOptionsWrapperTarget.classList.remove('hidden');
      this.paperclipOptionsWrapperTarget.querySelector("textarea").removeAttribute('disabled');
    }
  }

}
