import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ["input", "output"];
  static values = { maxChars: Number };

  initialize() {
    this.overClass = this.element.dataset.charLimitOverClass || "char-limit-over";
  }

  maxCharsValueChanged() {
    this.count();
  }

  count(event) {
    let value = this.inputTarget.value;
    let available = this.maxCharsValue - value.length;

    if (available < 0) {
      this.inputTarget.value = value.substring(0, this.maxCharsValue);
      this.outputTarget.textContent = "0";
      this.outputTarget.classList.add(this.overClass);
    } else {
      this.outputTarget.textContent = `${available}`;
      this.outputTarget.classList.remove(this.overClass);
    }
  }
}
