import { Controller } from 'stimulus';

export default class extends Controller {

  initialize() {
    this.element.pageSection = this;
    this.manageAddBtnVisibility();
    this.manageRemoveBtnVisibility();
  }

  moveUpBlock(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentBlock = $("#"+event.currentTarget.closest('.page-node-block').id);
    currentBlock.prev().insertAfter(currentBlock);
    this.manageRemoveBtnVisibility();
  }

  moveDownBlock(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}
    let currentBlock = $("#"+event.currentTarget.closest('.page-node-block').id);
    currentBlock.next().insertBefore(currentBlock);
    this.manageRemoveBtnVisibility();
  }


  removeBlock(event) {
    event.preventDefault();
    try { $('[data-toggle="tooltip"]').tooltip('hide'); } catch(e) {}

    let remove = confirm(event.currentTarget.closest('a').dataset.message);
    if (remove) {
      event.currentTarget.closest('.page-node-block').remove();
    }

    this.manageAddBtnVisibility();
  }

  manageAddBtnVisibility() {
    const section = $(this.element);

    const modelSection = JSON.parse(this.element.dataset.modelSection);
    if ( modelSection.blocks.some(b => b.repeatable) ) {
      // ci sono dati ripetibili in configurazione
      // mostro il pulsante
      section.find('.add-block-btn').show();
    } else {
      // non ci sono blocchi ripetibili in configurazione, solo singoli
      const singleBlockIds = modelSection.blocks.filter(b => !b.repeatable).map(b => String(b.block_id));
      const currentSingleBlockIds = section.find('.page-node-block[data-repeatable="false"]').get().map(b => b.dataset.blockId);
      const currentSingleBlockIdsSet = new Set(currentSingleBlockIds);
      let diff = singleBlockIds.filter(x => !currentSingleBlockIdsSet.has(x));
      if ( diff.length > 0 ) {
        // ci sono blocchi singoli che non sono stati aggiunti
        section.find('.add-block-btn').show();
      } else {
        section.find('.add-block-btn').hide();
      }
    }
  }

  manageRemoveBtnVisibility() {
    let section = $(this.element);
    let blockIds = [];

    section.find('.page-node-block').each(function() {
      let blockId = $(this).data('blockId');
      let required = $(this).data('required');
      if(required && $.inArray(blockId, blockIds) == -1) {
        $(this).find('.remove-block').hide();
        blockIds.push(blockId);
      } else {
        $(this).find('.remove-block').show();
      }
    });
  }

  newSectionBlock(event) {
    event.preventDefault();
    let section = $(this.element);
    let blockIds = [];
    section.find('.page-node-block').each(function() {
      blockIds.push($(this).data('blockId'));
    });

    let currentLang = section.closest('form').data('formTranslationsLangValue');

    $.ajax({
      type: 'GET',
      url: event.currentTarget.dataset.url,
      data: {current_block_ids: blockIds, current_lang: currentLang}
    });
  }

}
