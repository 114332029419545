import { Controller } from 'stimulus';

export default class extends Controller {

  initialize() {
    this.imgEl = this.element.dataset.imgEl;
    this.valueEl = this.element.dataset.valueEl;
    this.removeEl = this.element.dataset.removeEl;
  }

  setImage(event) {
    event.preventDefault();
    $(this.imgEl).attr('src', event.currentTarget.dataset.imageUrl);
    $(this.valueEl).val(event.currentTarget.dataset.imageId);
    $(this.removeEl).removeClass('hidden');
    $(this.element).closest('.modal').modal('hide');
  }

}
