import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["step1", "step2", "backBtn", "nextBtn", "saveBtn", "pageConfigNodeLabel"];

  step1(event) {
    event.preventDefault();

    this.step1Target.classList.remove("hidden");
    this.step2Target.classList.add("hidden");
    this.backBtnTarget.classList.add("hidden");
    this.nextBtnTarget.classList.remove("hidden");
    this.saveBtnTarget.classList.add("hidden");
  }

  step2(event) {
    event.preventDefault();

    this.step1Target.classList.add("hidden");
    this.step2Target.classList.remove("hidden");
    this.backBtnTarget.classList.remove("hidden");
    this.nextBtnTarget.classList.add("hidden");
    this.saveBtnTarget.classList.remove("hidden");
  }

  pageConfigNodeChanged(event) {
    if ( this.hasPageConfigNodeLabelTarget ) {
      let labelText = event.currentTarget.labels?.[0]?.textContent;
      if ( labelText ) {
        this.pageConfigNodeLabelTarget.innerText = labelText;
      }
    }
  }

}
