import {Controller} from "stimulus";

export default class extends Controller {

  static targets = ['completeBtn'];

  showSubmitBtn(e) {
    console.log("showSubmitBtn");
    $(e.currentTarget).closest('form').find('input[type="submit"]').removeClass('disabled');
  }
  hideSubmitBtn(e) {
    $(e.currentTarget).addClass('disabled');
  }

}
