import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { lang: String, section: String };

  changeLang(event) {
    this.langValue = event.currentTarget.dataset.lang;
  }

  changeSection(event) {
    this.sectionValue = event.currentTarget.dataset.section;
  }

  formSubmit(event) {
    event.preventDefault();
    const btn = event.currentTarget;
    const publish = btn.dataset.publish;
    const force = btn.dataset.force;
    const sendRemote = btn.dataset.sendRemote;

    const $form = $(btn.closest('form'));
    let uri = $form.attr('action');
    uri = this.updateUrlParameter(uri, 'lang', this.langValue);
    uri = this.updateUrlParameter(uri, 'tab', this.sectionValue);
    if ( publish === 'true' ) {
      uri = this.updateUrlParameter(uri, 'publish', 'true');
    }
    if ( force === 'true' ) {
      uri = this.updateUrlParameter(uri, 'force', 'true');
    }
    $form.attr('action', uri);

    if ( sendRemote === 'true' ) {
      $form.data('remote', 'true');
    }
    $form.submit();
  }

  updateUrlParameter(uri, key, value) {
    let i = uri.indexOf('#');
    let hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
  }

}
