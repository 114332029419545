import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['image', 'input'];

  removeImage(event) {
    event.preventDefault();

    $(this.inputTarget).val("");
    $(this.imageTarget).attr('src', $(this.imageTarget).data('placeholder-url'));

    $(event.currentTarget).addClass('hidden');
  }

}
