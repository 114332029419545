import {Controller} from 'stimulus';

export default class extends Controller {

  initialize() {
    $('.revision-icheck').iCheck({
      checkboxClass: 'icheckbox_square-green',
      radioClass: 'iradio_square-green',
    }).on('ifChecked', function(event){
      let publishedAt = event.target.dataset.publishedAt;
      let version = event.target.dataset.version;
      $('.preview-message').html(`Con il tasto "procedi con il ripristino" verrà ripristinata 
        la versione ${version} pubblicata il ${publishedAt}`);
    });
  }

}
