import iziToast from "izitoast"

const MyApp = {
  initOnLoad: function() {

  },

  flashMessage: function(name, msg, position){
    let obj = {
      message: msg,
      timeout: 8000,
      position: position
    };
    if (name === 'error'){
      obj.title = 'Errore';
      obj.color = 'red';
    } else if (name === 'notice') {
      obj.title = 'Successo';
      obj.color = 'green';
    } else if (name === 'alert') {
      obj.title = 'Attenzione';
      obj.color = 'yellow';
    }
    iziToast.show(obj);
  }
};

export default MyApp;
