import {Controller} from 'stimulus';
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ['picker', 'input'];

  connect() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      useAsButton: false,
      default: this.inputTarget.value || null,
      theme: 'classic', // or 'monolith', or 'nano'
      appClass: 'color-picker',

      swatches: [],
      lockOpacity: true,

      components: {

        // Main components
        preview: true,
        opacity: false,
        hue: true,

        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: true,
          save: true
        }
      }
    });

    this.picker.on('save', (color, instance) => {
      this.inputTarget.value = color?.toHEXA()?.toString() || '';
      instance.hide();
    });
  }

  disconnect() {
    this.picker.destroyAndRemove();
  }

}
