import {Controller} from "stimulus";

export default class extends Controller {

  connect() {
    if($(this.element).hasClass('is-spam-true')) {
      $(this.element).find('i')
        .on( "mouseenter", function() { $(this).removeClass('fa-times-circle').addClass('fa-check-circle-o') } )
        .on( "mouseleave", function() { $(this).addClass('fa-times-circle').removeClass('fa-check-circle-o') } );
    }

    if($(this.element).hasClass('is-spam-false')) {
      $(this.element).find('i')
        .on( "mouseenter", function() { $(this).removeClass('fa-check-circle').addClass('fa-times-circle-o') } )
        .on( "mouseleave", function() { $(this).addClass('fa-check-circle').removeClass('fa-times-circle-o') } );
    }
  }

}