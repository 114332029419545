import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['custom', 'bluecms', 'enbilabFilters', 'googleRecaptchaFilters'];

  senderKindChanged(event) {
    if(event.target.value == 'custom'){
      this.bluecmsTarget.classList.add('d-none')
      this.customTarget.classList.remove('d-none')
    } else if(event.target.value == 'bluecms'){
      this.bluecmsTarget.classList.remove('d-none')
      this.customTarget.classList.add('d-none')
    }
  }

  spamFilterKindChanged(event) {
    if(event.currentTarget.value === 'enbilab') {
      $('.enbilab-filter').removeClass('d-none');
      $('.google-recaptcha-filter').addClass('d-none');
    } else if(event.currentTarget.value === 'google_recaptcha') {
      $('.enbilab-filter').addClass('d-none');
      $('.google-recaptcha-filter').removeClass('d-none');
    } else {
      $('.enbilab-filter').addClass('d-none');
      $('.google-recaptcha-filter').addClass('d-none');
    }
  }

}
