import { Controller } from 'stimulus';
import {computeSelAfterChange} from "codemirror/src/model/change_measurement";

export default class extends Controller {

  initialize() {
    this.titleEl = this.element.dataset.titleEl;
    this.valueEl = this.element.dataset.valueEl;
  }

  setReference(event) {
    event.preventDefault();
    const $titleEl = $(this.titleEl);

    let fullTitle = [];
    $(event.currentTarget).parents('.page-node-group').map(function() {
      return fullTitle.push(this.dataset.title);
    });
    $titleEl.html(fullTitle.reverse().join(" / "));

    $(this.valueEl).val(event.currentTarget.dataset.pageNodeId);
    $titleEl.closest('.component-reference').find('.add-link').addClass('hidden');
    $titleEl.closest('.component-reference').find('.remove-link').removeClass('hidden');

    $('#ajax-modal').modal('hide');
  }

  resetReference(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const confirmMsg = button.dataset.confirmMsg || "Sei sicuro?";
    const doRemove = confirm(confirmMsg);
    if ( doRemove ) {
      const $titleEl = $(this.titleEl);
      $titleEl.html('');
      $(this.valueEl).val('');

      $titleEl.closest('.component-reference').find('.add-link').removeClass('hidden');
      $titleEl.closest('.component-reference').find('.remove-link').addClass('hidden');
    }
  }


}
